import React from 'react';
import { graphql } from 'gatsby';
import { cloneDeep } from 'lodash';

import Subheader from '../components/Subheader/Subheader';
import Services from '../components/Services/Services';
import Contacts from '../components/Contacts/Contacts';
import ProductBar from '../components/Product/ProductBar';
import ProductInfo from '../components/Product/ProductInfo';
import ProductCommons from '../components/Product/ProductCommons';
import ProductGallery from '../components/Product/ProductGallery';
import ProductScope from '../components/Product/ProductScope';
import ProductPairs from '../components/Product/ProductPairs';
import ProductSpecifications from '../components/Product/ProductSpecifications';
import Seo from '../components/SEO/SEO';

import { IProductProps } from './Types';

import { LANGUAGES, TRANSLATE } from '../constants/languages';
import RecommendedSlider from '../components/ProductSlider/RecommendedSlider';
import QuestionsForm from '../components/QuestionsForm/QuestionsForm';
import ProductSlider from '../components/ProductSlider/ProductSlider';
import { IProductSlider } from '../components/ProductSlider/Types';
import { ICommonProduct } from '../components/Product/Types';
import StepsSlider from '../components/StepsSlider/StepsSlider';
import paymentItems from '../constants/paymentItems';

const Product: React.FC<IProductProps> = ({ data: { cockpitProduct } }): JSX.Element => {
  const {
    name: { value: name },
    lang,
    id,
    link: { value: productLink },
    description: { value: description },
    brand,
    price: { value: price },
    price2,
    price3,
    price4,
    measurment,
    measurment2,
    measurment3,
    measurment4,
    productAvailable,
    stock,
    images: { value: images },
    galleryTitle,
    galleryText,
    galleryBackground,
    galleryImages,
    productScopeText,
    productScopes,
    commonProducts,
    productPairs,
    specifications,
    code: { value: code },
    subcategory: {
      value: {
        title: { value: subCategoryTitle },
        link: { value: subCategoryLink },
        category: {
          value: {
            title: { value: categoryTitle },
            link: { value: categoryLink },
          },
        },
      },
    },
  } = cockpitProduct;

  const productCrumbs = [
    {
      title: TRANSLATE[lang as 'ru' | 'ua'].primaryPage,
      link: LANGUAGES[lang as 'ru' | 'ua'],
    },
    {
      title: 'Каталог',
      link: `${LANGUAGES[lang as 'ru' | 'ua']}catalog`,
    },
    {
      title: categoryTitle,
      link: categoryLink,
    },
    {
      title: subCategoryTitle,
      link: subCategoryLink,
    },
    {
      title: name,
      link: productLink,
    },
  ];

  const seoImages = images.map(
    ({
      childImageSharp: {
        fluid: { src },
      },
    }) => src
  );

  const product = {
    price,
    seoImages,
    url: productLink,
  };

  let specificationsData = null;

  if (specifications?.value && specifications?.value?.data) {
    specificationsData = specifications.value.data;
  }

  if (brand && brand?.value) {
    specificationsData = {
      Бренд: brand.value,
      ...specificationsData,
    };
  }

  const commonProductsNodes: IProductSlider[] = commonProducts?.value
    ? cloneDeep(commonProducts?.value).map((item: ICommonProduct) => {
        const cockpitLabel = 'Cockpit__Product__';
        const productId = item.id.replace(cockpitLabel, '').split('_')[0];
        const productImage = item.images.value[0];
        // eslint-disable-next-line no-param-reassign
        delete item.id;
        // eslint-disable-next-line no-param-reassign
        delete item.images;

        return {
          ...item,
          parentId: {
            value: productId,
          },
          image: {
            value: productImage,
          },
        };
      })
    : [];

  // if (brand && brand?.value && specifications?.value && specifications?.value?.data) {
  //   specifications.value.data['Бренд'] = brand?.value;
  // }

  return (
    <div className="page">
      <Seo
        breadcrumbs={productCrumbs}
        description={description}
        lang={lang as 'ru' | 'ua'}
        path={productLink}
        product={product}
        title={name}
      />
      <Subheader crumbs={productCrumbs} />
      {/* <ProductBar /> */}
      <ProductInfo
        name={name}
        description={description}
        price={price}
        price2={price2?.value || ''}
        price3={price3?.value || ''}
        price4={price4?.value || ''}
        measurment={measurment?.value || ''}
        measurment2={measurment2?.value || ''}
        measurment3={measurment3?.value || ''}
        measurment4={measurment4?.value || ''}
        images={images}
        productAvailable={productAvailable?.value || null}
        code={code}
        stock={stock?.value || ''}
        id={id}
        type="product"
        category={categoryTitle}
        brand={brand?.value || ''}
      />
      {(description || specificationsData) && <ProductSpecifications description={description} data={specificationsData} title={name} />}
      {/* {galleryImages && galleryBackground && galleryBackground && galleryTitle && (
        <ProductGallery
          title={galleryTitle?.value}
          description={galleryText?.value}
          background={galleryBackground?.value}
          images={galleryImages.value}
        />
      )} */}
      {/* <ProductScope desciption={productScopeText} scopes={productScopes} /> */}
      {!!commonProducts?.value?.length && <ProductSlider nodes={commonProductsNodes} title="Схожі товари" productKey="common" />}
      <RecommendedSlider />
      <div style={{ width: '100%' }}>
        <QuestionsForm />
      </div>
      {productPairs && <ProductPairs productPairs={productPairs?.value} />}
      <StepsSlider title="Оплата та доставка" items={paymentItems} />
      <Contacts />
    </div>
  );
};

export default Product;

export const productQuery = graphql`
  query($path: String!) {
    cockpitProduct(link: { value: { eq: $path } }, lang: { ne: "any" }) {
      lang
      id
      link {
        value
      }
      name {
        value
      }
      description {
        value
      }
      brand {
        value
      }
      productAvailable {
        value
      }
      measurment {
        value
      }
      measurment2 {
        value
      }
      measurment3 {
        value
      }
      measurment4 {
        value
      }
      price {
        value
      }
      price2 {
        value
      }
      price3 {
        value
      }
      price4 {
        value
      }
      stock {
        value
      }
      specifications {
        value {
          data
        }
      }
      code {
        value
      }
      images {
        value {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      galleryTitle {
        value
      }
      galleryText {
        value
      }
      galleryImages {
        value {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      galleryBackground {
        value {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      productScopeText {
        value
      }
      commonProducts {
        value {
          id
          name {
            value
          }
          link {
            value
          }
          price {
            value
          }
          measurment {
            value
          }
          code {
            value
          }
          images {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      productScopes {
        value {
          id
          title {
            type
            value
          }
          svg {
            type
            value
          }
        }
      }
      commonProducts {
        value {
          id
          name {
            value
          }
          link {
            value
          }
          images {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      productPairs {
        value {
          lang
          id
          link {
            value
          }
          stock {
            value
          }
          name {
            value
          }
          description {
            value
          }
          productAvailable {
            value
          }
          measurment {
            value
          }
          measurment2 {
            value
          }
          measurment3 {
            value
          }
          measurment4 {
            value
          }
          price {
            value
          }
          price2 {
            value
          }
          price3 {
            value
          }
          price4 {
            value
          }
          stock {
            value
          }
          specifications {
            value {
              data
            }
          }
          code {
            value
          }
          image {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          images {
            value {
              id
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      subcategory {
        value {
          title {
            value
          }
          link {
            value
          }
          category {
            value {
              title {
                value
              }
              link {
                value
              }
            }
          }
        }
      }
    }
  }
`;
